<div class="cont">
  <div class="contend">
    <div class="cont-sec">
      <div class="img-fond">
        <div class="ba"></div>
        <img src="../../../../../assets/images/gradient-godigital.png" alt="" />
      </div>
      <div class="info-text">
        <div>
          <h1 class="somos-font-20">GODIGITAL</h1>
          <h1 class="transfor">
            ¡Tambien te podemos asesorar!
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cont mt-5 mt-md-3 backcolor">
  <div class="row cont">
    <div class="col-12 col-md-6 cont">
      <div class="contend-about">
        <div class="about-info">
          <div>
            <h3>GODIGITAL</h3>
            <h1>
              Te ofrecemos consultorías para acompañarte en todo el proceso de
              tu Estrategia Digital, desde el concepto hasta la implementación.
            </h1>
            <p>
              Te podemos ayudar a desarrollar tus estrategias digital para
              mejorar tus ingresos, retener clientes o mejorar tus productos.
              Útilizando herramientas actuales que no son necesariamente
              costosas o simplemente no están en tu radar y si estan en el
              nuestro.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-0 col-sm-0 col-md-6 cont">
      <div
        class="about-img"
        [ngClass]="{
          animate__animated: hoverImg && hoverImgFirst,
          animate__slideInRight: hoverImg && hoverImgFirst
        }"
      >
        <img src="../../../../../assets/images/img-godigital.png" alt="" />
      </div>
    </div>
  </div>
</div>

<div class="cont">
  <div class="proyect">
    <div class="row cont">
      <div class="col-0 col-md-5 col-lg-7 cont">
        <div class="ima">
          <div>
            <img src="../../../../../assets/images/Frame (3).png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 col-lg-5 cont">
        <div class="question">
          <div>
            <h1>¿Tienes un proyecto en mente?</h1>
            <p>
              ¡Nuestros objetivos, son tus objetivos! Desde las más pequeñas
              empresas hasta las más grandes necesitan tener estrategias
              digitales para comunicarse y desarrollar a sus clientes.
            </p>
            <p>
              Hablamos de transformación digital transversal, de poner la
              tecnologia al servicio. No implementar software o herramientas por
              hacerlo, sino adaptarla y personalizarla a tus necesidades únicas.
            </p>

            <button [routerLink]="['/contact']"> CONTÁCTANOS</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cont">
 <app-contact-in-pages></app-contact-in-pages> 
</div>
