
<div class="cont">
   <div class=" row cont-proyect cont">
    <div class="proyect col-12 col-md-7">
        <h1>¡Cuéntanos tu proyecto!</h1>
        <p>
            Hablamos de transformación digital transversal, de poner la 
            tecnología al servicio de tu negocio. No implementar software o 
            herramientas por hacerlo, si no adaptarlo y personalizarlo a tus 
            necesidades únicas.
        </p>
    </div>
    <div class="proyect-form  col-12 col-md-5">
        <p>Deja tu mensaje</p>
        <form (submit)="sendMessage(form)" #form="ngForm">
            <div class="row">
                <div class="col-12 inp">
                 <input type="text" name="email" [(ngModel)]="user.correo"  placeholder="Correo Electrónico" required="">
                </div>
                <div class="col-12 inpt">
                 <textarea type="text" name="message" [(ngModel)]="user.mensaje" placeholder="Mensaje" rows="5" required=""></textarea>
                </div>
                <div class="col-12 btn">
                    <button type="submit" *ngIf="!sending">Enviar</button>
                    <button class="im" type="button" [disabled]="sending" *ngIf="sending">Enviando <img src="../../../../assets/images/spinner-email.gif" alt=""></button>
                </div>
            </div>
            
        </form>
    </div>


  
   </div>
</div>