import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { formatCurrency } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  URL= environment.URL_SENDMAIL;

  constructor(private http: HttpClient) { }

  send(body:any):Observable<any>{
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': '*/*',
    };
    const requestOptions= {                                                                                                                                                                             
      headers: new HttpHeaders(headerDict)
    };
     return this.http.post(this.URL,body,requestOptions)
  }
}
