import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { ShowFooterService } from 'src/app/services/show-footer.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    '../../../../node_modules/keen-slider/keen-slider.min.css',
    './home.component.scss',
  ],
})
export class HomeComponent implements OnInit {
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  slider: KeenSliderInstance = null;

  public hoverImg: boolean = false;
  public hoverImgFirst: boolean = true;
  public isCell: boolean = false;

  public elementPerView: number = 5;

  constructor(public show: ShowFooterService) {
    this.show.showBool = true;
    if (window.innerWidth <= 767) {
      this.isCell = true;
    }
    if (window.innerWidth >= 768 && window.innerWidth <= 991) {
      this.isCell = true;
    }

    if (window.innerWidth <= 576) {
      this.elementPerView = 1;
    } else {
    }
  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: true,
      rtl: true,
      slides: {
        perView: 3,
        spacing: 10,
      },
    });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    window.addEventListener('scroll', (e) => {
      if (scrollY >= 1300 && scrollY <= 1500) {
        this.hoverImg = true;
      }
      if (scrollY >= 2100) {
        this.hoverImgFirst = false;
        this.hoverImg = false;
      }
    });
  }
}
