
<div class="cont">
    <div class="row cont">
        
        <div class="col-12 col-md-12 col-lg-7 cont" >
           <div class="texto cont">
                <div class="imagess">
                    <div class="cont-img" >
                        <img src="../../../assets/images/GoLoyalty.png" alt="">
                    </div>
                    <div class="image-cell" >
                        <img src="../../../assets/images/landing/img-landing2.png" alt="">
                    </div>
                </div>
                <div class="des"  >
                    <p class="tit" >
                        Una de las mejores formas de generar más ingresos es 
                        fidelizando a los clientes ya existentes.
                    </p>
                    <p class="tit-des" >
                        Te presentamos la <span>plataforma más poderosa y flexible</span> 
                        del mercado para crear y administrar tu propio 
                        programa de lealtad. <span>100% digital, 100% autogestionado</span>.
                    </p>
                </div>
           </div>
        </div>
        <div class="col-12 col-md-0  col-lg-5 cont">
            <div class="image">
                <img src="../../../assets/images/landing/img-landing2.png" alt="">
            </div>
            
        </div>
    </div>
</div>



<app-slider></app-slider>



<div class="cont">
    <div class="nuestra-plataforma">
        <div class="green">
           <div>
              <div>
                <p>Nuestra plataforma cuenta con su portal exclusivo al cliente 
                    que le permitirá ver TUS promociones, TUS productos y TUS noticias. 
                    Además ver todas sus transacciones en tu comercio.
                </p>
              </div>
           </div>
        </div>
        <div class="add">
            <p>Algunos comercios usando GoLoyalty</p>
        </div>
        <div class="cont-prods">
           <div class="prods">
            <div class="row">
                <div class="col-6 col-md-3 colbx">
                    <div class="bx">
                        <img src="../../../assets/images/clients/barrioCafeLogo.png" alt="">
                    </div>

                </div>
                <div class="col-6 col-md-3 colbx">
                    <div class="bx">
                        <img src="../../../assets/images/clients/Contentera.png" alt="">
                    </div>
                </div>
                <div class="col-6 col-md-3 colbx">
                    <div class="bx">
                        <img src="../../../assets/images/clients/ranchitoLogo.png" alt="">
                    </div>
                </div>
                <div class="col-6 col-md-3 colbx">
                    <div class="bx">
                        <img src="../../../assets/images/clients/zocaloLogo.jpg" alt="">
                    </div>
                </div>
            </div>
          
           
           
          
           </div>
        </div>
        <div class="form">
            <h1>Conoce, Fideliza, Recompensa...y gana!</h1>
            <div class="fo">
               <div class="contac">
                  <p>Solicita 2 meses de prueba ¡GRATIS!</p>
                  <div>
                      <form (submit)="register(form)" #form="ngForm">
                        <div class="row cont">
                            <div class="col-6 inp mb-5">
                             <input type="text" placeholder="Nombre" name="name" [(ngModel)]="user.nameclient" required>
                            </div>
                            <div class="col-6 inp mb-5">
                                <input type="text" placeholder="Apellido" name="lastname" [(ngModel)]="user.lastname" required>
                            </div>
                            <div class="col-6 inp">
                                <input type="text" placeholder="Número"  name="phone" [(ngModel)]="user.phone" required>
                               </div>
                               <div class="col-6 inp">
                                   <input type="text" placeholder="Correo"  name="correo" [(ngModel)]="user.correo" required>
                               </div>
                               <div class="col-12 inpt mt-5">
                                <textarea name="" id="" rows="5" placeholder="Mensaje"  name="mensaje" [(ngModel)]="user.mensaje" required></textarea>
                            </div>
                
                            <div class="col-12 btn">
                                <button type="submit" *ngIf="!sending">Enviar</button>
                                <button class="im" type="button" [disabled]="sending" *ngIf="sending">Enviando <img src="../../../assets/images/spinner-email.gif" alt=""></button>
                            </div>
                        </div>
                      </form>
                  </div>
               </div>
            </div>
        </div>
    </div>
</div>