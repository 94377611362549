import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {
 
  private readonly URL= environment.url;


  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(this.URL)
    .pipe(
       map((res: any) => {
        var array = [];
        var contador = -1;
        if(res !== null){
          Object.keys(res).forEach(element => {
            let blog = res[element];
            contador++;
            blog.iden = Object.keys(res)[contador];
            array.push(blog);
          });
        }
         return array;
      })
    )
  }

}
