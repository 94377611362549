import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: "home",
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
  },
  {
    path: "about",
    loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule),
  },
  {
    path: "products",
    loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule),
  /*   children:[
      {
        path: "GoLoyalty",
        component: GoloyaltyComponent
      },
      {
        path: "GoPromo",
        component: GopromoComponent
      },
      {
        path: "GoCommerce",
        component: GocommerceComponent
      },
      {
        path: "GoSales",
        component: GosalesComponent
      },
      {
        path: "GoDigital",
        component: GodigitalComponent
      }
    ] */
  },
  {
    path: "blog",
    loadChildren: () => import('./components/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: "promogl",
    loadChildren: () => import('./components/promogl/promogl.module').then(m => m.PromoglModule),
  },
  {
    path: "contact",
    loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: "**",
    pathMatch: "full",
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
