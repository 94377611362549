<div class="cont">
    <div class="contend">
        <div class="cont-sec">
            <div class="img-fond">
                <div class="ba"></div>
                <img src="../../../../../assets/images/gradiente-gosales.png" alt="" />
              </div>
              <div class="info-text">
                <div>
                  <h1 class="somos-font-20">GOSALES</h1>
                  <h1 class="transfor">Plataforma de incentivo al vendedor o punto de venta. 
                     100% en línea
                  </h1>
                </div>
              </div>
        </div>
    </div>
</div>


<div class="cont mt-5 backcolor">
    <div class="row cont">
      <div class="col-12 col-md-6 cont">
       
        <div class="contend-about">
            <div class="about-info ">
                <div >
                  <h3>GOSALES</h3>
                  <h1>Nuestra plataforma para 
                      incentivar al vendedor o 
                      punto venta de forma 100%
                      digital y en tiempo real. Ésta 
                      plataforma es autogestionable y ajustable a 
                      cualquier tipo de industrias 
                      o sector comercial.
                  </h1>
                  <p>
                  GoSales hace un tracking de metas alcalzadas por comercio y por 
                  vendedor, y los retroalimenta para incentivar el alcance del 
                  objetivo. Con inteligencia comercial, la plataforma hace los incentivos 
                  de forma automática y acorde a los intereses de tu campaña.
                  </p> 
                </div>
              </div>
        </div>
      </div>
      <div class="col-md-6 cont">
        <div class="about-img animate__animated animate__rotateInDownRight">
            <img src="../../../../../assets/images/img-gosales.png" alt="" />
          </div>
      
      </div>
    </div>
  </div>

  <div class="cont mt-2 mt-md-5 mb-4">
    <div class="cont-info-and-image">
        <h1>GOSALES</h1>
         <div>
          <img src="../../../../../assets/images/3.png" alt="">
         </div>
    </div>
</div>

<div class="cont ">
  <div class="row cont">
      <div class="col-12 cont">
          <div class="info-list">
              <h1>GOSALES</h1>
          </div>
      </div>
      <div class="col-12 col-md-5 cont">
          <div class="info-list">
             <div class="div-no-list">
              <p class="p-no-list">
                  Una plataforma
                  autogestionable,
                  fácilmente
                  parametrizable y
                  ajustable.
                </p>
             </div>
          </div>
      </div>
      <div class="col-12 col-md-7 cont">
          <div class="info-list">
             <div>
                <div>
                  <span><p class="guion">-</p><p class="parrafo">Dashboard de control y fácil de modelar</p></span>
                  <span><p class="guion">-</p><p class="parrafo">Portal para clientes</p></span>
                  <span><p class="guion">-</p><p class="parrafo">CRM para administrar clientes</p></span>
                  <span><p class="guion">-</p><p class="parrafo">Recibir feedback en forma ágil</p></span>
                  <span><p class="guion">-</p><p class="parrafo">Incentivar la rotación de inventario nuevo</p></span>
                  <span><p class="guion">-</p><p class="parrafo">Valiosos INSIGHTS para la toma de decisiones comerciales.</p></span>
                 </div>
             </div>
          </div>
      </div>
  </div>
</div>

<div class="cont">
  <app-contact-in-pages></app-contact-in-pages> 
</div>
