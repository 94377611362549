<div class="cont">
  <div class="services">
    <div>
      <div class="info">
        <div class="back-ima">
          <img src="../../../assets/images/vectores/Vector 3.png" alt="" />
        </div>
        <h3>NUESTROS SERVICIOS</h3>
        <h1>¿Qué podemos hacer por tu negocio?</h1>
      </div>

      <div class="cont-sw">
        <swiper
          [effect]="'coverflow'"
          [grabCursor]="true"
          [centeredSlides]="true"
          [slidesPerView]="elementPerView"
          [coverflowEffect]="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false
          }"
          [pagination]="true"
          class="mySwiper"
        >
        <ng-template swiperSlide>
          <div class="card-slider">
            <div class="card-img">
              <img
                src="../../../assets/images/GoLoyaltyLogoSmall-mod.png"
                alt=""
              />
            </div>
            <p class="title">GoLoyalty</p>
            <p class="card-info">
              Un programa de lealtad 100% digital y autogestionable por el
              comercio. Recompensa a tus clientes fieles, aumenta tus ingresos
              y capta más y mejores clientes con ésta poderosa herramienta.
            </p>
            <a [routerLink]="['/products/GoLoyalty']"
              >VER MÁS<i class="fa-solid fa-arrow-right"></i
            ></a>
          </div> </ng-template >
          <ng-template swiperSlide>
            <div class="card-slider">
              <div class="card-img">
                <img src="../../../assets/images/GoSalesLogoSmall.png" alt="" />
              </div>
              <p class="title">GoSales</p>
              <p class="card-info">
                Nuestra plataforma para incentivar al vendedor o punto venta de
                forma 100% digital y en tiempo real. Ésta plataforma es autogestionable 
                y ajustable a cualquier tipo de industrias o sector
                comercial.
              </p>
              <a [routerLink]="['/products/GoSales']"
                >VER MÁS<i class="fa-solid fa-arrow-right"></i
              ></a>
            </div> </ng-template >
          <ng-template swiperSlide>
            <div class="card-slider">
              <div class="card-img">
                <img
                  src="../../../assets/images/GoCommerceLogoSmal-modl.png"
                  alt=""
                />
              </div>
              <p class="title">GoCommerce</p>
              <p class="card-info">
                Nuestra plataforma para implementar comercio electrónico de
                forma rápida, fácil y segura Ecommerce es parte esencial en la
                estrategia de crecimiento de cualquier negocio.
              </p>
              <a [routerLink]="['/products/GoCommerce']"
                >VER MÁS<i class="fa-solid fa-arrow-right"></i
              ></a>
            </div> </ng-template
          ><ng-template swiperSlide>
            <div class="card-slider">
              <div class="card-img">
                <img
                  src="../../../assets/images/GoPromoLogoSmal-modl.jpg"
                  alt=""
                />
              </div>
              <p class="title">GoPromo</p>
              <p class="card-info">
                Incrementa tus clientes y fortalece tu relación con ellos, a
                través de dinámicas contextuales, relevantes y digitales Conectá
                y medí el retorno de pauta online con ventas en tu tienda
                física.
              </p>
              <a [routerLink]="['/products/GoPromo']"
                >VER MÁS<i class="fa-solid fa-arrow-right"></i
              ></a>
            </div>
          </ng-template>
         
       
            <ng-template swiperSlide>
            <div class="card-slider">
              <div class="card-img">
                <img
                  src="../../../assets/images/GoDigitalLogoSmall-mod.png"
                  alt=""
                />
              </div>
              <p class="title">Asesorías y Outsourcing</p>
              <p class="card-info">
                ¿Cuál es tu estrategia o tu ambición digital actual? ¿Qué
                necesidades tienes? Consultorías para acompañarte en todo el
                proceso de tu Estrategia Digital, desde el concepto hasta la
                implementación
              </p>
              <a [routerLink]="['/products/GoDigital']"
                >VER MÁS<i class="fa-solid fa-arrow-right"></i
              ></a>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
  </div>
</div>
