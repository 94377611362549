import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SendEmailService } from '../../../services/send-email.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-in-pages',
  templateUrl: './contact-in-pages.component.html',
  styleUrls: ['./contact-in-pages.component.scss']
})
export class ContactInPagesComponent implements OnInit {

  public user: any = {};
  public sending:boolean =false;

  constructor(private email: SendEmailService) { }

  ngOnInit(): void {
  }

  sendMessage(form: NgForm){
    if(form.invalid){
      Swal.fire({
        title: '¡Los campos son requeridos!',
        icon: 'warning'
      });
      return;
    }
    this.sending = true;
    this.user.type = 'inPages'
    this.email.send(this.user).subscribe((res:any) => {
      Swal.fire({
        icon: 'success',
        title: '¡Correo enviado con exito!'
      });
      this.sending = false;
      form.reset();
    },(err:any) => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: '¡Algo salio mal!'
      });
      this.sending = false;
    })
     
  }

}
