import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactInPagesRoutingModule } from './contact-in-pages-routing.module';
import { ContactInPagesComponent} from './contact-in-pages.component'
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [ContactInPagesComponent],
  imports: [
    CommonModule,
    ContactInPagesRoutingModule,
    FormsModule,
  ],
  exports:[
    ContactInPagesComponent
  ]
})
export class ContactInPagesModule { }
