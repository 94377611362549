import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

/* import 'swiper/swiper-bundle.css';
import Swiper, { Navigation, Pagination } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions } from 'swiper'; */

import { SwiperComponent } from 'swiper/angular';

// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination, Navigation, Autoplay]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements OnInit {
  public elementPerView: number = 3;

  /*   config: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  onSwiper([swiper]) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  } */

  constructor() {
    if (window.innerWidth <= 576) {
      this.elementPerView = 1;
    } else {
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
