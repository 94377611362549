<div class="cont">
    <div class="services">
      <div>
        <div class="info">
          <div class="back-ima">
            <img src="../../../assets/images/vectores/Vector 3.png" alt="" />
          </div>
          <h3>Tus reglas de acumulación, tus reglas de canje, tus 
              productos de canje. Un programa exclusivo para tus clientes.
          </h3>
          <h1>Algunas carasterísticas de GoLoyalty:</h1>
        </div>
  
        <div class="cont-sw">
          <swiper
            [effect]="'coverflow'"
            [grabCursor]="true"
            [centeredSlides]="true"
            [slidesPerView]="elementPerView"
            [coverflowEffect]="{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false
            }"
            [pagination]="true"
            class="mySwiper"
          >
          <ng-template swiperSlide>
            <div class="card-slider">
              <div class="card-img">
                <img
                  src="../../../../assets/images/landing/iconos landing GD-01.png"
                  alt=""
                />
              </div>
            </div> </ng-template >
            <ng-template swiperSlide>
              <div class="card-slider">
                <div class="card-img">
                  <img src="../../../../assets/images/landing/iconos landing GD-02.png" alt="" />
                </div>
              </div> </ng-template >
            <ng-template swiperSlide>
              <div class="card-slider">
                <div class="card-img">
                  <img
                    src="../../../../assets/images/landing/iconos landing GD-03.png"
                    alt=""
                  />
                </div>
              </div> </ng-template
            ><ng-template swiperSlide>
              <div class="card-slider">
                <div class="card-img">
                  <img
                    src="../../../../assets/images/landing/iconos landing GD-04.png"
                    alt=""
                  />
                </div>

              </div>
            </ng-template>
           
         
              <ng-template swiperSlide>
              <div class="card-slider">
                <div class="card-img">
                  <img
                    src="../../../../assets/images/landing/iconos landing GD-05.png"
                    alt=""
                  />
                </div>
              </div>
            </ng-template>
            <ng-template swiperSlide>
                <div class="card-slider">
                  <div class="card-img">
                    <img
                      src="../../../../assets/images/landing/iconos landing GD-06.png"
                      alt=""
                    />
                  </div>
                </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card-slider">
                  <div class="card-img">
                    <img
                      src="../../../../assets/images/landing/iconos landing GD-07.png"
                      alt=""
                    />
                  </div>
                </div>
              </ng-template>
          </swiper>
        </div>
      </div>
    </div>
  </div>
  
