import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShowFooterService } from './services/show-footer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NewWebSite';

  constructor(public showfooter: ShowFooterService){
  }
 

  ngOnInit(): void {
   
  }

}
