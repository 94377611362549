import { Component, OnInit } from '@angular/core';
import { ShowFooterService } from 'src/app/services/show-footer.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(  public show : ShowFooterService) { 
    this.show.showBool = true;
  }

  ngOnInit(): void {
  }
  ngOnDestroy() {
    window.scroll(0,0);
  }

}
