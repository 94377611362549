<div class="cont">
 <div class="contend cont">
  <div class="cont-sec cont">
    <div class="img-fond cont">
      <div class="ba cont"></div>
      <img src="../../../assets/images/Rectangle 2.png" alt="" />
    </div>
    <div class="info-text cont">
      <div>
        <h1 class="somos-font-20">SOMOS GODIGITAL</h1>
        <h1 class="transfor">¡Transformación Digital para todos!</h1>
      </div>
    </div>
  </div>
 </div>
</div> 

 <div class="cont ">
  <div class="row cont cc">
    <div class="col-md-6 cont">
      <div class="about-img animate__animated animate__bounceInLeft cont">
        <img src="../../../assets/images/vectabout.png" alt="" />
      </div>
    </div>
    <div class="col-12 col-md-6 cont contend-cont">
       <div class="contend-about cont">
        <div class="about-info cont">
          <div class="cont">
            <h3>SOBRE NOSOTROS</h3>
            <h1>Transformación Digital</h1>
            <p>
              Somos un grupo de emprededores con vasta experiencia en el mundo de
              las telecomunicaciones y la tecnología, con años de servicio
              trabajando exitósamente en operadores móviles, así como empresas de
              servicio de valor agregado.
            </p>
            <p>
              Toda ésta experiencia nos permite trasladar nuestros conocimientos
              de ésta exigente industria a los demás sectores de mercado.
            </p>
            <p>
              Fundados en 2019 con la única misión de ser creativos, innovadores y
              no dejar de imaginar. Nos apasiona la gestión digital, el potencial
              de los servicios digitales y la innnovación en nuestra región.
            </p>
          </div>
        </div>
       </div>
    </div>
  </div>
</div>  

 <div class="cont mb-5 mb-md-0">
  <div class="business cont">
    <h3>NUESTROS SERVICIOS</h3>
    <h1>¿Qué podemos hacer por tu negocio?</h1>
    <div class="cards-bus cont">
      <div class="row cont">
        <div class="col-6 cont">
          <div class="card-bu-left mb-4 cont"  [routerLink]="['/products/GoCommerce']" style="cursor: pointer;">
            <div>
              <img src="../../../assets/images/GoCommerce.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-6 cont">
          <div class="card-bu-right mb-4 cont"  [routerLink]="['/products/GoLoyalty']" style="cursor: pointer;">
            <div>
              <img src="../../../assets/images/GoLoyalty.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-6 cont">
          <div class="card-bu-left cont"  [routerLink]="['/products/GoPromo']" style="cursor: pointer;">
            <div>
              <img src="../../../assets/images/GoPromo.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-6 cont">
          <div class="card-bu-right cont"  [routerLink]="['/products/GoSales']" style="cursor: pointer;">
            <div>
              <img src="../../../assets/images/GoSales.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 

<div class="cont mb-5 mb-md-0">
    <div class="team cont">
        <h3>NUESTRO EQUIPO </h3>
        <h1>Somos transformación digital</h1>
        <div class="cont">
            <div class="row cont">
                <div class="col-4 cont">
                    <div class="team-img-l  cont">
                       <div >
                           <img src="../../../assets/images/Orlando.png" alt="Orlando Mendoza">
                           <p class="name">Orlando Mendoza</p>
                            <p class="des">Director de IT / Cofundador</p>
                       </div>
                    </div>
                </div>
                <div class="col-4 cont">
                    <div class="team-img-c cont">
                       <div >
                           <img src="../../../assets/images/Jorge.png" alt="Jorge Fernández">
                           <p class="name">Jorge Fernández</p>
                            <p class="des">Director de Producto / Cofundador</p>
                       </div>
                    </div>
                </div>
                <div class="col-4 cont">
                    <div class="team-img-r cont">
                       <div >
                           <img src="../../../assets/images/Jose.png" alt="José Montealegre">
                           <p class="name">José Montealegre</p>
                            <p class="des">Director de Negocios / Cofundador</p>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="cont">
    <app-contact-in-pages></app-contact-in-pages> 
</div>
