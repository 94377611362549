import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { ContactInPagesComponent } from '../shared/contact-in-pages/contact-in-pages.component';
import { SliderComponent } from './slider/slider.component';
import { ContactInPagesModule } from '../shared/contact-in-pages/contact-in-pages.module';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';


@NgModule({
  declarations: [HomeComponent,SliderComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ContactInPagesModule,
    SwiperModule
  ]
})
export class HomeModule { }
