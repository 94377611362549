import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PromoglRoutingModule } from './promogl-routing.module';
import { PromoglComponent } from './promogl.component';
import { SliderComponent } from './slider/slider.component'
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [PromoglComponent, SliderComponent],
  imports: [
    CommonModule,
    PromoglRoutingModule,
    SwiperModule,
    FormsModule
  ],
  exports:[
    PromoglComponent,
    SliderComponent
  ]
})
export class PromoglModule { }
