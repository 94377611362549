
<div class="load" *ngIf="loading">
  <img src="../../../assets/images/spinnercube.gif" alt="">
</div>

<div class="cont1" *ngIf="!loading">
  <div class="cont2">
    <div class="all" *ngFor="let blog of data">
      <div class="card imagen" *ngIf="blog?.type === 'imagen'">
        <div class="hea">
          <img src="../../../assets/images/GoDigitalLogoSmall-mod.png" alt="" />
          <div class="fecha">
            <h5>GoDigital</h5>
            <p>{{ blog.created_ad |  date: "short" }}</p>
          </div>
        </div>
        <h5>{{ blog.title }}</h5>
        <p>{{ blog.description }}</p>
        <a [href]="blog.enlace" *ngIf="blog.enlace !== 'null'" target="_blank" [ngClass]="{'mar': blog.enlace !== 'null'}">{{ blog.enlace }}</a>
        <img style="margin-top: 7px;" [src]="blog.imagen" alt="" />
      </div>
      <div class="card imagenes"  *ngIf="blog?.type === 'imagenes'">
        <div class="hea">
          <img src="../../../assets/images/GoDigitalLogoSmall-mod.png" alt="" />
          <div class="fecha">
            <h5>GoDigital</h5>
            <p>{{ blog.created_ad |  date: "short" }}</p>
          </div>
        </div>
        <h5>{{ blog.title }}</h5>
        <p>{{ blog.description }}</p>  
        <a [href]="blog.enlace" *ngIf="blog.enlace !== 'null'" target="_blank">{{ blog.enlace }}</a>
      <app-images [imagen]="blog.imagen" style="margin-top: 20px;"></app-images>
      </div>
      <div class="card video"  *ngIf="blog?.type === 'video'">
        <div class="hea">
          <img src="../../../assets/images/GoDigitalLogoSmall-mod.png" alt="" />
          <div class="fecha">
            <h5>GoDigital</h5>
            <p>{{ blog.created_ad  | date: "short" }}</p>
          </div>
        </div>
        <h5>{{ blog.title }}</h5>
        <p>{{ blog.description }}</p>
        <a [href]="blog.enlace" *ngIf="blog.enlace !== 'null'" target="_blank">{{ blog.enlace }}</a>
       <div class="player-wrapper" style="margin-top: 20px;">
            <vg-player>
              <video #media [vgMedia]="media" id="singleVideo" preload="auto" controls>
                <source
                  [src]="blog.video"
                  [type]="blog.format"
                />
               
              </video>
            </vg-player>
          </div> 
        
      </div>
      <div class="card text"  *ngIf="blog?.type === 'text'">
        <div class="hea">
          <img src="../../../assets/images/GoDigitalLogoSmall-mod.png" alt="" />
          <div class="fecha">
            <h5>GoDigital</h5>
            <p>{{ blog.created_ad  |  date: "short" }}</p>
          </div>
        </div>
         <h5>{{ blog.title }}</h5>
         <p>{{ blog.description }}</p>
         <a [href]="blog.enlace" *ngIf="blog.enlace !== 'null'" target="_blank">{{ blog.enlace }}</a>
      </div>
    </div>
  </div>
</div>








<!-- <div class="cont">
    <app-contact-in-pages></app-contact-in-pages>
</div>
   -->
