import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogRoutingModule } from './blog-routing.module';
import {BlogComponent} from './blog.component';
import {ImagesComponent} from './images/images.component';
import { SwiperModule } from "swiper/angular";

@NgModule({
  declarations: [BlogComponent,ImagesComponent],
  imports: [
    CommonModule,
    BlogRoutingModule,
    SwiperModule,
  ]
})
export class BlogModule { }
