<nav>
    <div class="logo" [routerLink]="['/Home']">
        <img src="../../../../assets/images/logo.png" alt="">
    </div>
   <!--  <div class="menu-cell">
        <button (click)="showmenu()"><i class="fa-solid fa-bars"></i></button>
    </div> -->
    <div   class="menu" [ngClass]="{'noshowmenu': !showmenuB && isCell, 'showmenu': showmenuB && isCell }">
      <div class="bt-conact-cell">
            <div>
                <a target="_blank" [href]="ngx_whatsapp_url" class="ngx-whatsapp-button-float">
                  <div ><i class="fa-brands fa-whatsapp"></i>+507 6617 0079</div>
                </a>
              </div>
        </div>
        <div class="m">
            <button [routerLink]="['/home']" (click)="isClicked()">INICIO</button>
        </div>
        <div class="m">
            <button [routerLink]="['/about']" class="quienes" (click)="isClicked()">QUIENES SOMOS</button>
        </div>
        <div class="dropdown">
            <button  class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" >PRODUCTOS</button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item"  [routerLink]="['/products/GoLoyalty']">GoLoyalty</a></li>
                <li><a class="dropdown-item"   [routerLink]="['/products/GoPromo']">GoPromo</a></li>
                <li><a class="dropdown-item"  [routerLink]="['/products/GoCommerce']">GoCommerce</a></li>
                <li><a class="dropdown-item"    [routerLink]="['/products/GoSales']">GoSales</a></li>
                <li><a class="dropdown-item"    [routerLink]="['/products/GoDigital']">GoDigital</a></li>
                <li><a class="dropdown-item"    [routerLink]="['/products/PromoGL']">PromoGL</a></li>
              </ul>
        </div>
        <div class="dropdown-cell m">
            <button  class="togglem" (click)="menuchange()" >PRODUCTOS</button>
             <div [ngClass]="{'menucell':menu, 'nomenucell': !menu}">
                <ul class="menum" >
                    <li><a class="dropdown-item" [routerLink]="['/products/GoLoyalty']" (click)="isClicked()">GoLoyalty</a></li>
                    <li><a class="dropdown-item"  [routerLink]="['/products/GoPromo']" (click)="isClicked()">GoPromo</a></li>
                    <li><a class="dropdown-item" [routerLink]="['/products/GoCommerce']" (click)="isClicked()">GoCommerce</a></li>
                    <li><a class="dropdown-item"  [routerLink]="['/products/GoSales']" (click)="isClicked()">GoSales</a></li>
                    <li><a class="dropdown-item"  [routerLink]="['/products/GoDigital']" (click)="isClicked()">GoDigital</a></li>
                    <li><a class="dropdown-item"  [routerLink]="['/products/PromoGL']" (click)="isClicked()">PromoGL</a></li>

                  </ul>
             </div>
        </div>

        <div class="m">
            <button  [routerLink]="['/blog']" (click)="isClicked()">BLOG</button>
        </div>

        <div class="m">
            <button  [routerLink]="['/contact']" (click)="isClicked()">CONTACTO</button>
        </div>


    </div>
    <div class="btn-phone">
        <div>
          <a target="_blank" [href]="ngx_whatsapp_url" class="ngx-whatsapp-button-float">
            <div ><i class="fa-brands fa-whatsapp"></i>+507 6617 0079</div>
          </a>
        </div>
    </div>
   <div class="menu-cell">
        <button (click)="showmenu()"><i class="fa-solid fa-bars"></i></button>
    </div>
</nav>
