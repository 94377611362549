<div class="cont">
    <div class="contend">
        <div class="cont-sec">
            <div class="img-fond">
                <div class="ba"></div>
                <img src="../../../../../assets/images/img-coloyalty.png" alt="" />
              </div>
              <div class="info-text">
                <div>
                  <h1 class="somos-font-20">GOLOYALTY</h1>
                  <h1 class="transfor">Fidelizar clientes ahora es más fácil</h1>
                </div>
              </div>
        </div>
    </div>
</div>


<div class="cont mt-3 backcolor">
    <div class="row cont">
      <div class="col-md-6 cont">
        <div class="about-img" [ngClass]="{'animate__animated': hoverImg && hoverImgFirst, 'animate__rotateInDownLeft': hoverImg && hoverImgFirst}">
          <img src="../../../../../assets/images/img-goloyalty.png" alt="" />
        </div>
      </div>
      <div class="col-12 col-md-6 cont">
        <div class="contend-about">
            <div class="about-info">
                <div>
                  <h3>GOLOYALTY</h3>
                  <h1>La plataforma más sencilla y 
                      poderosa para crear tu programa de lealtad
                  </h1>
                  <p>
                   Nuestra plataforma para crear programas de lealtad es totalmente 
                   digital y totalmente autogestionable.
                  </p>
                  <p>
                   Diseñada para facilitar la creación y administración de tu propio 
                   programa de lealtad. Creamos una herramienta de inteligencia 
                   comercial para ayudarte a colectar información de tus clientes y
               
                   mejorar tu oferta comercial.
                  </p>
                  <p>
                   Nuestro modelo de "Software as a service" hace que tu empresa no 
                   requiera invertir en hardware, software especial, ni en mantenimiento.
                  </p>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cont mt-4 mt-md-0">
      <div class="cont-info-and-image">
          <h1>FEATURES DE GOLOYALTY</h1>
           <div>
            <img src="../../../../../assets/images/imagenMOD.png" alt="">
           </div>
      </div>
  </div>

  <div class="cont mt-5  ">
      <div class="row cont">
          <div class="col-12 cont">
              <div class="info-list">
                  <h1 class="mb-0 mb-md-5">GOLOYALTY</h1>
              </div>
          </div>
          <div class="col-12 col-md-5 cont">
              <div class="info-list">
                 <div class="div-no-list">
                    <p class="p-no-list">
                        Nuestra plataforma para 
                        crear programas de
                        lealtad es totalmente 
                        digital y totalmente 
                        autogestionable.
                     </p>
                 </div>
              </div>
          </div>
          <div class="col-12 col-md-7 cont">
              <div class="info-list">
                 <div>
                    <div>
                        <span><p class="guion">-</p><p class="parrafo">Dashboard de control intuitivo y fácil de modelar</p></span>
                        <span><p class="guion">-</p><p class="parrafo">Portal para clientes</p></span>
                        <span><p class="guion">-</p><p class="parrafo">CRM para administrar clientes</p></span>
                        <span><p class="guion">-</p><p class="parrafo">MarketPlace Digital</p></span>
                        <span><p class="guion">-</p><p class="parrafo">Envío de Noticias y Notificaciones directas al cliente</p></span>
                        <span><p class="guion">-</p><p class="parrafo">Interfaz para el cajero fácil de entender, "easy to cook - easy to eat"</p></span>
                        <span><p class="guion">-</p><p class="parrafo">Crea listados segmentados de usuarios para hacer campañas y promociones.</p></span>
                     </div>
                 </div>
              </div>
          </div>
      </div>
  </div>

  <div class="cont">
    <app-contact-in-pages></app-contact-in-pages>
  </div>