import { Component, OnInit } from '@angular/core';
import { SendEmailService } from 'src/app/services/send-email.service';
import { ShowFooterService } from 'src/app/services/show-footer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-promogl',
  templateUrl: './promogl.component.html',
  styleUrls: ['./promogl.component.scss']
})
export class PromoglComponent implements OnInit {

  public user: any = {};
  public sending: boolean = false;

  constructor(public show : ShowFooterService,
    private email: SendEmailService) {  this.show.showBool = true;}

  ngOnInit(): void {
  }

  register(form){
    if(form.invalid){
      Swal.fire({
        title: '¡Los campos son requeridos!',
        icon: 'warning'
      })
      return;
    }
    this.sending = true;
    this.user.type = 'contact';
    this.email.send(this.user).subscribe((res:any) => {
      Swal.fire({
        icon: 'success',
        title: '¡Correo enviado con exito!'
      });
      this.sending = false;
      form.reset();
    },(err:any) => {
      Swal.fire({
        icon: 'error',
        title: '¡Algo salio mal!'
      });
      this.sending = false;
    }) 

  }

}
