import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BlogsService } from '../../services/blogs.service';


import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from "keen-slider";
import { ShowFooterService } from 'src/app/services/show-footer.service';


function ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active")
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active")
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          main.moveToIdx(idx)
        })
      })
    }

    slider.on("created", () => {
      addActive(slider.track.details.rel)
      addClickEvents()
      main.on("animationStarted", (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(next)
      })
    })
  }
}






@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss','../../../../node_modules/keen-slider/keen-slider.min.css'] //../../node_modules/keen-slider/keen-slider.min.css
})
export class BlogComponent implements OnInit {


  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  @ViewChild("thumbnailRef") thumbnailRef: ElementRef<HTMLElement>

  slider: KeenSliderInstance = null
  thumbnailSlider: KeenSliderInstance = null

  public loading: boolean = true;
 

  public data: any = [];


  constructor(private blogservice: BlogsService,
               public show : ShowFooterService) { 
    this.show.showBool = false;
  }

  ngOnInit(): void {
    this.getPost();
    this.show.showBool = false;
  }

  getPost(){
    this.blogservice.get().subscribe((res:any) => {
      this.data = res;
      this.loading = false;
    })
  }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement)
    this.thumbnailSlider = new KeenSlider(
      this.thumbnailRef.nativeElement,
      {
        initial: 0,
        slides: {
          perView: 4,
          spacing: 10,
        },
      },
      [ThumbnailPlugin(this.slider)]
    )
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
    if (this.thumbnailSlider) this.thumbnailSlider.destroy();
    window.scroll(0,0);
  }

  





}
