import { Component, OnInit } from '@angular/core';
import { ShowFooterService } from 'src/app/services/show-footer.service';

@Component({
  selector: 'app-gosales',
  templateUrl: './gosales.component.html',
  styleUrls: ['./gosales.component.scss']
})
export class GosalesComponent implements OnInit {

  constructor(  public show : ShowFooterService) { 
    this.show.showBool = true;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    window.scroll(0,0);
  }

}
