import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HttpClientModule  } from '@angular/common/http';



import { FormsModule } from '@angular/forms';

// video
import { VgCoreModule, } from '@videogular/ngx-videogular/core';
import { HomeModule } from './components/home/home.module';
import { AboutModule } from './components/about/about.module';
import { BlogModule } from './components/blog/blog.module';
import { ProductsModule } from './components/products/products.module';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    VgCoreModule,
    HomeModule,
    AboutModule,
    BlogModule,
    ProductsModule,
    NgxWhastappButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
