<div class="cont">
    <div class="contend">
        <div class="cont-sec">
            <div class="img-fond">
                <div class="ba"></div>
                <img src="../../../../../assets/images/GRADIENT-GOCOMMERCE.png" alt="" />
              </div>
              <div class="info-text">
                <div>
                  <h1 class="somos-font-20">GOCOMMERCE</h1>
                  <h1 class="transfor">El comercio en línea repunta en nuestro país</h1>
                </div>
              </div>
        </div>
    </div>
</div>


<div class="cont backcolor">
    <div class="row cont">
      <div class="col-12 col-md-7 cont">
       
        <div class="contend-about">
            <div class="about-info">
                <div>
                  <h3>GOCOMMERCE</h3>
                  <h1>Vender en línea no es algo 
                      exclusivo para grandes
                      empresas, es algo obligatorio
                      para todos los comercios.
                  </h1>
                  <p>
                   Nuestra propuesta para implementar Ecommerce de forma fácil, rápida y segura 
                   para el cliente y el comercio.
                  </p>
                  <p>
                   Con nuestra plataforma podés tener tu tienda en minutos, podés utilizar tu propio 
                   POS virtual o el nuestro. El ingreso de tus ventas cae directamente en NICARAGUA
                   ¡Tu dinero inmediatamente!.
                  </p>
                  <p>
                  Utiliza las redes sociales existentes como medio para interactuar, posicionar y 
                  vender mis productos de forma segura. Te proporcionamos una interfaz para el 
                  comercio fácil e intuitiva para manejar los pedidos directamente en la plataforma.
                  </p>
                </div>
              </div>
        </div>
      </div>
      <div class="col-0 col-sm-0 col-md-5 col-lg-5 cont">
        <div class="about-img animate__animated animate__rotateInDownRight">
            <img src="../../../../../assets/images/img-gocommerce.png" alt="" />
          </div>
      
      </div>
    </div>
  </div>

  <div class="cont mt-3 mb-5">
    <div class="cont-info-and-image">
        <h1>¿QUÉ PODÉS HACER CON LA PLATAFORMA GOCOMMERCE?</h1>
         <div class="mt-5">
          <img src="../../../../../assets/images/11 1.png" alt="">
         </div>
    </div>
</div>


<div class="cont mt-5 ">
  <div class="text_img">
    <div class="row cont">
        <div class="col-12 col-lg-4 col-md-6 cont">
           <div class="llego">
              <div>
                <h1>GOCOMMERCE</h1>
                <p>Llegó el momento 
                    de ser parte de la 
                    digital life que se 
                    vive en todo el 
                    mundo.
                </p>
              </div>
           </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 cont">
          <div class="list">
              <div class="c1">
                <div class="c2">
                    <span><p class="guion">-</p><p class="parrafo">Repunta la vida digital desde casa</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Todos trabajamos en casa</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Todos jugamos en casa</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Todos consumimos en casa</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Todo llega a casa.</p></span>
                 </div>
             </div>
          </div>
        </div>
        <div class="col-0 col-md-0 col-lg-4 cont">
             <div class="ima">
                <div>
                    <img src="../../../../../assets/images/Frame (5).png" alt="">
                </div>
             </div>
        </div>
    </div>
  </div>
</div>



<div class="cont">
 <app-contact-in-pages></app-contact-in-pages> 
</div>