import { Component, OnInit } from '@angular/core';
import { ShowFooterService } from 'src/app/services/show-footer.service';

@Component({
  selector: 'app-gopromo',
  templateUrl: './gopromo.component.html',
  styleUrls: ['./gopromo.component.scss']
})
export class GopromoComponent implements OnInit {

  public hoverImg:boolean = false;
  public hoverImgFirst:boolean = true;

  constructor(  public show : ShowFooterService) { 
    this.show.showBool = true;
  }

  ngOnInit(): void {
    window.addEventListener('scroll',(e) => {
      if(scrollY >= 400 && scrollY <= 700){
        this.hoverImg = true;
      }
      if(scrollY >= 2100){
        this.hoverImgFirst = false;
        this.hoverImg = false;
      }
    })
  }
  ngOnDestroy() {
    window.scroll(0,0);
  }


}
