import { Component, OnInit } from '@angular/core';
import { ShowFooterService } from 'src/app/services/show-footer.service';

@Component({
  selector: 'app-godigital',
  templateUrl: './godigital.component.html',
  styleUrls: ['./godigital.component.scss']
})
export class GodigitalComponent implements OnInit {

  
  public hoverImg:boolean = false;
  public hoverImgFirst:boolean = true;

  constructor(  public show : ShowFooterService) {
    this.show.showBool = true;
   }

  ngOnInit(): void {
    window.addEventListener('scroll',(e) => {
      if(scrollY >= 300 && scrollY <= 600){
        this.hoverImg = true;
      }
      if(scrollY >= 900){
        this.hoverImgFirst = false;
        this.hoverImg = false;
      }
    })
  }

  ngOnDestroy() {
    window.scroll(0,0);
  }

}
