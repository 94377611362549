import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsRoutingModule } from './products-routing.module';
import {GocommerceComponent } from './product-list/gocommerce/gocommerce.component';
import {GodigitalComponent } from './product-list/godigital/godigital.component';
import {GoloyaltyComponent } from './product-list/goloyalty/goloyalty.component';
import {GopromoComponent } from './product-list/gopromo/gopromo.component';
import {GosalesComponent } from './product-list/gosales/gosales.component';
import {ContactInPagesComponent } from '../shared/contact-in-pages/contact-in-pages.component';
import { ContactInPagesModule } from '../shared/contact-in-pages/contact-in-pages.module';
import { PromoglModule } from '../promogl/promogl.module';


@NgModule({
  declarations: [GocommerceComponent,GodigitalComponent,GoloyaltyComponent,GopromoComponent,GosalesComponent],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    ContactInPagesModule,
    PromoglModule
  ]
})
export class ProductsModule { }
