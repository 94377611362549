import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PromoglComponent } from '../promogl/promogl.component';
import { GocommerceComponent } from './product-list/gocommerce/gocommerce.component';
import { GodigitalComponent } from './product-list/godigital/godigital.component';
import { GoloyaltyComponent } from './product-list/goloyalty/goloyalty.component';
import { GopromoComponent } from './product-list/gopromo/gopromo.component';
import { GosalesComponent } from './product-list/gosales/gosales.component';

const routes: Routes = [
  {
    path: "",
    children:[
      {
        path: "GoLoyalty",
        component: GoloyaltyComponent
      },
      {
        path: "GoPromo",
        component: GopromoComponent
      },
      {
        path: "GoCommerce",
        component: GocommerceComponent
      },
      {
        path: "GoSales",
        component: GosalesComponent
      },
      {
        path: "GoDigital",
        component: GodigitalComponent
      },
      {
        path: "PromoGL",
        component: PromoglComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
