import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import SwiperCore, { EffectCoverflow, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit {

  public elementPerView: number = 3;

  constructor() { }

  ngOnInit(): void {
    if (window.innerWidth <= 767) {
      this.elementPerView = 1;
   } else {
    
   }
  }

}
