import { Component, OnInit } from '@angular/core';
import { ShowFooterService } from 'src/app/services/show-footer.service';

@Component({
  selector: 'app-gocommerce',
  templateUrl: './gocommerce.component.html',
  styleUrls: ['./gocommerce.component.scss']
})
export class GocommerceComponent implements OnInit {

  constructor(  public show : ShowFooterService) {
    this.show.showBool = true;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    window.scroll(0,0);
  }

}
