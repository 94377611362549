import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public menu:boolean = false;
  public showmenuB:boolean = false;
  public isCell: boolean = false;
/*   public phone = '[ +50576813595 ]'
  public title = '[ hola desde la web ]'; */


   @Input('ngx_whatsapp_phone') ngx_whatsapp_phone: string = '50766170079';
   @Input('ngx_whatsapp_text') ngx_whatsapp_text: string = 'Hola! estoy interesado en sus servicios';

   public ngx_whatsapp_icon;
   public ngx_whatsapp_url = 'https://api.whatsapp.com/send' ;
   public ngx_whatsapp_url_full;



  constructor() {
   this.showmenuB = false;


   if (window.innerWidth <= 767) {
    this.isCell = true;
  }
  if(window.innerWidth >= 768 && window.innerWidth <= 991 ) {
    this.isCell = true;
  }

  }

  ngOnInit(): void {
     // we set the icon
     this.ngx_whatsapp_icon = faWhatsapp;
     //verify if we had a number
     this.ngx_whatsapp_phone = ( this.ngx_whatsapp_phone !== undefined ) ? this.ngx_whatsapp_phone : '';
     this.ngx_whatsapp_text = ( this.ngx_whatsapp_text !== undefined ) ? this.ngx_whatsapp_text : '';
     this.ngx_whatsapp_url = `${this.ngx_whatsapp_url}?phone=${this.ngx_whatsapp_phone}&text=${this.ngx_whatsapp_text}`;
  }

  menuchange(){
    this.menu = !this.menu;
  }

  showmenu(){
   this.showmenuB = !this.showmenuB;
    if(this.menu) this.menu = false;
  }

  isClicked(){
    this.showmenuB = false;
  }

}
