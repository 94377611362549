<div class="cont cont-footer">
   <img src="../../../../assets/images/vectores/Vector 4.png" alt="">
   <img class="only-cell" src="../../../../assets/images/vectores/Vector 4.png" alt="">
   <div class="row cont">
       <div class="col-12">
           <div class="footer">
              <div>
                <h1>Contacto</h1>
                <p class="contact"> contacto@godigitalgroup.net</p>
                <p class="dir">Plaza Izabella. Las Colinas, Managua Nicaragua </p>
                <div class="redes">
                    <ol>
                        <li><a href="https://www.facebook.com/Go-Digital-Group-101984404545450/" target="_blank"><i class="fa-brands fa-facebook-square"></i></a></li>
                        <li><a href="https://www.instagram.com/godigitalnic/"  target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/51607894/admin/"  target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
                    </ol>
                </div>
                <div class="line"><div></div></div>
                <div class="copy">GoDigital Group © {{ date }} Copyright</div>
              </div>
           </div>
       </div>
   </div>

   <div class="btn-up">
       <button (click)="up()"><i class="fa-solid fa-arrow-up"></i></button>
   </div>
</div>
