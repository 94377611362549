<div class="cont">
    <div class="contend">
        <div class="cont-sec">
            <div class="img-fond">
                <div class="ba"></div>
                <img src="../../../../../assets/images/land-gocommerce.png" alt="" />
              </div>
              <div class="info-text">
                <div>
                  <h1 class="somos-font-20">GOPROMO</h1>
                  <h1 class="transfor">Captura nuevos clientes e 
                      incentiva nuevos productos, 
                      de forma inteligente, 
                      segmentada y oportuna.
                  </h1>
                </div>
              </div>
        </div>
    </div>
</div>

<div class="cont backcolor">
    <div class="row cont">
      <div class="col-md-6 cont about-img">
        <div class="about-img " [ngClass]="{'animate__animated': hoverImg && hoverImgFirst, 'animate__rotateInDownLeft': hoverImg && hoverImgFirst}">
          <img src="../../../../../assets/images/img-gopromo.png" alt="" />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 cont">
        <div class="contend-about">
            <div class="about-info">
                <div>
                  <h3>GOPROMO</h3>
                  <h1>Promueve interacciones con 
                      tu público meta para 
                      incentivar cambios de comportamiento. Captura 
                      nuevos leads de forma innovadora y divertida. La 
                      diferenciación es importante.
                  </h1>
                  <span>
                    <p>
                      Los tiempos cambiaron, ahora todo es, y tiene que ser, digital.
                    </p>
                  </span>
                  <p>
                   GoPromo puede funcionar como excelente Plataforma para que las 
                   nuevas marcas, los nuevos productos llamen la atención del 
                   consumidor de forma diferente.
                  </p>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cont mb-md-0 mb-5">
    <div class="cont-info-and-image">
        <h1>INSIGHTS</h1>
         <div>
          <img src="../../../../../assets/images/GoPromoMod.png" alt="">
         </div>
    </div>
</div>

<div class="cont ">
    <div class="row cont">
        <div class="col-12 cont">
            <div class="info-list">
                <h1>GOPROMO</h1>
            </div>
        </div>
        <div class="col-12 col-md-5 cont">
            <div class="info-list">
               <div class="div-no-list">
                <p class="p-no-list">
                    Estratégicamente 
                    éstas dinámicas 
                    permitirán...
                  </p>
               </div>
            </div>
        </div>
        <div class="col-12 col-md-7 cont">
            <div class="info-list">
               <div>
                  <div>
                    <span><p class="guion">-</p><p class="parrafo">Generar engagement con la marca</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Posicionar productos nuevos</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Construir una base de datos de sus consumidore finales</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Recibir feedback en forma ágil</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Incentivar la rotación de inventario nuevo</p></span>
                    <span><p class="guion">-</p><p class="parrafo">Valiosos INSIGHTS para la toma de decisiones comerciales.</p></span>
                   </div>
               </div>
            </div>
        </div>
    </div>
</div>

<div class="cont">
 <app-contact-in-pages></app-contact-in-pages> 
</div>


